//  ███╗...███╗██╗██╗..██╗██╗███╗...██╗███████╗....██╗...██╗
//  ████╗.████║██║╚██╗██╔╝██║████╗..██║██╔════╝....╚██╗.██╔╝
//  ██╔████╔██║██║.╚███╔╝.██║██╔██╗.██║███████╗.....╚████╔╝.
//  ██║╚██╔╝██║██║.██╔██╗.██║██║╚██╗██║╚════██║......╚██╔╝..
//  ██║.╚═╝.██║██║██╔╝.██╗██║██║.╚████║███████║.......██║...
//  ╚═╝.....╚═╝╚═╝╚═╝..╚═╝╚═╝╚═╝..╚═══╝╚══════╝.......╚═╝...
//  ........................................................
//  ███████╗██╗...██╗███╗...██╗.██████╗██╗.██████╗.███╗...██╗███████╗███████╗
//  ██╔════╝██║...██║████╗..██║██╔════╝██║██╔═══██╗████╗..██║██╔════╝██╔════╝
//  █████╗..██║...██║██╔██╗.██║██║.....██║██║...██║██╔██╗.██║█████╗..███████╗
//  ██╔══╝..██║...██║██║╚██╗██║██║.....██║██║...██║██║╚██╗██║██╔══╝..╚════██║
//  ██║.....╚██████╔╝██║.╚████║╚██████╗██║╚██████╔╝██║.╚████║███████╗███████║
//  ╚═╝......╚═════╝.╚═╝..╚═══╝.╚═════╝╚═╝.╚═════╝.╚═╝..╚═══╝╚══════╝╚══════╝
//  .........................................................................

@use 'sass:math'; // Propiedad de sass para operaciones matemáticas

// Mixin para call to actions
@mixin calltoactions($paddingY, $paddingX) {
  padding: $space_x2;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 80px;
  font-weight: $medium;
  color: $color-claro;
  background-color: $color-primario;
  transition: all 0.3s ease-in-out;
  @include font-size(23px);

  &:hover {
    text-decoration: none;
    text-transform: uppercase;
    color: $color-primario;
    background-color: get-opacity($color-secundario, 0.8);
  }
}

@mixin subHeaderDropdown {
  position: relative;
  display: inline-block;

  button {
    display: flex;
    align-items: center;
    border: none;
    color: $color-claro;
    background-color: transparent;
    cursor: pointer;
    @include font-size(18px);

    svg {
      margin-right: $space;
    }

    .arrowDownIcon {
      width: 15px;
      height: 11px;
      margin-left: $space;
      transform: rotate(90deg);
      transition: transform 500ms ease-in-out both;
      path {
        fill: $color-claro;
      }
    }

    &:hover {
      color: $color-secundario;
    }

    &:hover .arrowDownIcon {
      path {
        fill: $color-secundario;
      }
    }
  }

  &__content {
    display: none;
    padding-top: $space_x2;
    position: absolute;
    top: 25px;
    left: -6px;
    z-index: 99;
    min-width: 219px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
    transition: 500ms ease-in-out;

    a {
      padding: $space $space_x2;
      display: block;
      text-decoration: none;
      transition: 400ms ease-in-out;
      border-left: 1px solid get-opacity($color-oscuro, 0.1);
      border-right: 1px solid get-opacity($color-oscuro, 0.1);
      border-bottom: 1px solid get-opacity($color-oscuro, 0.1);
      color: $color-primario;
      background-color: $color-claro;
      @include font-size(17px);

      &:hover {
        color: $color-claro;
        background-color: get-opacity($color-primario, 0.9);
      }
    }

    .triangle {
      position: absolute;
      top: 10px;
      left: 11px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
    }
  }

  &:hover &__content {
    display: block;
  }

  &:hover button {
    color: $color-secundario;
  }

  &:hover .arrowDownIcon {
    path {
      fill: $color-secundario;
    }
  }

  &:hover &-icon {
    fill: $color-secundario;
  }

  @include media(desktop-s) {
    button {
      @include font-size(16px);

      svg {
        margin-right: $space_half;
      }

      .arrowDownIcon {
        width: 10px;
        height: 10px;
      }
    }

    &__content {
      top: 18px;
      left: -15px;
    }
  }

  @include media(desktop-m) {
    button {
      @include font-size(18px);

      svg {
        margin-right: $space;
      }

      .arrowDownIcon {
        width: 11px;
        height: 12px;
      }
    }

    &__content {
      top: 21px;
      left: -15px;
    }
  }

  @include media(desktop-l) {
    &__content {
      top: 22px;
      left: -15px;
    }
  }

  @include media(desktop-xxl) {
    &__content {
      top: 25px;
      left: -6px;
    }
  }
}

//Funcion para el texto responsivo
@function calculateRem($size) {
  $remSize: math.div($size, 16px);
  @return $remSize * 1rem;
}

// Dependiendo del tamaño del texto se selecciona un alto de linea automatica
@mixin font-size($size) {
  font-size: calculateRem($size);
  line-height: calculateRem($size) * 1.5;
}

// Opacidad: backgound-color: get-opacity($color-primario, .5)
@function get-opacity($color, $amount) {
  @return rgba($color, $amount);
}
