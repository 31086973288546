.retailer {
  position: fixed;
  top: 0;
  left: 50px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
  opacity: 0;
  user-select: none;
  transition: all 0.3s ease-in-out;
  animation: fadeIn 0.1s ease-in-out 0s both;

  &.isExpand {
    left: 212px;
  }

  &__close {
    padding: 0;
    position: absolute;
    top: 60px;
    right: 120px;
    z-index: 1;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    @include font-size(15px);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__search {
    padding: $space_x3 $space_x5;
    margin: 0 auto;
    width: 100%;
    max-width: 352px;
    height: 100vh;
    border-left: 1px solid $color-claro;
    background-color: $color-oscuro;
    animation: fadeIn 0.3s ease-in-out 0s both;

    h3 {
      margin-bottom: $space_x3;
      font-weight: $normal;
      color: $color-claro;
      @include font-size(12px);
    }
  }

  &__input {
    position: relative;
    display: flex;
    margin: 0 $space $space;
    border: 1px solid $color-claro;

    &__square {
      position: absolute;
      top: -11px;
      left: -11px;
      width: 10px;
      height: 10px;
      background-color: $color-primario;
    }

    input {
      padding: $space_half $space;
      width: 100%;
      height: 30px;
      outline: none;
      font-family: $Fuente1;
      color: $color-claro;
      border: none;
      background-color: transparent;
      @include font-size(12px);

      &::placeholder {
        color: get-opacity($color-claro, 0.5);
      }

      &::selection {
        color: $color-claro;
        background-color: $color-primario;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      min-width: 30px;
      cursor: pointer;
      color: $color-claro;
      background-color: get-opacity($color-primario, 1);

      &[data-type='reset'] {
        background-color: transparent !important;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:hover {
        background-color: get-opacity($color-primario, 0.9);
      }
    }
  }

  &__details {
    position: relative;
    width: 100%;
    height: 100vh;
    text-transform: uppercase;
    background-color: get-opacity($color-bg-oscuro, 0.98);
    animation: fadeIn 1s ease-in-out 0.2s both;

    h1 {
      padding: $space_x5;
      text-transform: none;
      max-width: 600px;
      color: $color-claro;
      @include font-size(42px);
    }

    h2 {
      color: $color-claro;
      @include font-size(18px);
    }

    h3 {
      color: $color-secundario;
      @include font-size(18px);
    }
  }

  &__container {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 550px;
    height: 500px;
    transform: translate(-50%, -50%);
    animation: fadeIn 1s ease-in-out 0.2s both;

    &__images {
      padding: $space_x3 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    img,
    video {
      width: 270px;
      height: 265px;
      object-fit: cover;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      animation: fadeIn 1s ease-in-out 0.5s both;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &__results {
    padding: 0 $space_half $space_x2;
    grid-column-gap: $space_half;
    width: 100%;
    max-height: 89%;
    overflow-y: auto;
    overflow-x: hidden;
    animation: fadeIn 1s ease-in-out 0.2s both;
    @include display-grid;
    @include grid-template-columns(1fr 1fr);

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 50px;
      background-color: get-opacity($color-claro, 0.2);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: get-opacity($color-claro, 0.5);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: get-opacity($color-claro, 1);
    }
  }

  &__other {
    padding: 0 0 $space_x2;
    width: 100%;
    max-height: 70vh;
    grid-auto-rows: min-content;
    overflow-x: hidden;
    overflow-y: auto;
    animation: fadeIn 1s ease-in-out 0.4s both;
    @include display-grid;
    @include grid-template-columns(50% 50%);

    h4 {
      grid-column: 1 / -1;
      margin-bottom: $space;
      font-weight: $normal;
      color: $color-claro;
      @include font-size(12px);

      span {
        color: get-opacity($color-claro, 0.5);
      }
    }

    &::-webkit-scrollbar {
      width: 2px;
      height: 1;
    }

    &::-webkit-scrollbar-track {
      border-radius: 50px;
      background-color: get-opacity($color-claro, 0.2);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: get-opacity($color-claro, 0.5);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: get-opacity($color-claro, 1);
    }
  }

  &__message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    & > div {
      width: 100px;
    }

    img {
      width: 100px;
      aspect-ratio: 1/1;
    }

    p {
      width: 200px;
      text-align: center;
      color: $color-claro;
      @include font-size(12px);
    }
  }
}
