.searchpanels {
  padding: 0 0;
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  user-select: none;
  color: $color-claro;
  background-color: $color-oscuro;

  h3 {
    margin-bottom: $space_x2;
    font-weight: $normal;
    @include font-size(12px);
  }

  &__container {
    padding-bottom: $space_x9;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-claro;
    }

    &::-webkit-scrollbar-track {
      background: get-opacity($color-claro, 0.2);
    }

    .error {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $color-negative;
    }
  }

  &__input {
    position: relative;
    display: flex;
    margin: 0 $space $space;
    border: 1px solid $color-claro;

    &__square {
      position: absolute;
      top: -11px;
      left: -11px;
      width: 10px;
      height: 10px;
      background-color: $color-primario;
    }

    input {
      padding: $space_half $space;
      width: 100%;
      height: 30px;
      outline: none;
      font-family: $Fuente1;
      color: $color-claro;
      border: none;
      background-color: transparent;
      @include font-size(12px);

      &::placeholder {
        color: get-opacity($color-claro, 0.5);
      }

      &::selection {
        color: $color-claro;
        background-color: $color-primario;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      min-width: 30px;
      cursor: pointer;
      color: $color-claro;
      background-color: get-opacity($color-primario, 1);

      &[data-type='reset'] {
        background-color: transparent !important;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:hover {
        background-color: get-opacity($color-primario, 0.9);
      }
    }
  }
}
