.chartscreen {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100vh;
  min-height: 630px;
  animation: fadeIn 0.3s ease-in-out 0s both;
  transition: all 0.3s ease-in-out;
  color: $color-claro;
  background-color: get-opacity($color-bg-oscuro, 0.9);

  .spark {
    padding: 6px 6px;
    margin: 0 $space_half;
    width: 10px;
    height: 10px;

    &.evo {
      background-color: $color-secundario;
    }

    &.gaming {
      background-color: $color-variante;
    }

    &.riser {
      background: $color-gradient-violeta;
    }

    &.ultra {
      background-color: $color-variante-nueve;
    }
  }

  &__close {
    padding: 0;
    position: absolute;
    top: 40px;
    right: 120px;
    z-index: 1;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    @include font-size(15px);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__container {
    padding: $space_x5 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    width: 80%;
    max-width: 1124px;
    height: 500px;
    animation: fadeIn 0.3s ease-in-out 0.2s both;
  }

  &__title {
    padding: $space $space_x2;
    display: flex;
    align-items: center;
    width: 100%;
    color: $color-claro;
    transition: all 0.3s ease-in-out;

    &.all {
      background-color: $color-primario;
    }

    &.evo {
      background-color: $color-secundario;
    }

    &.gaming {
      background-color: $color-variante;
    }

    &.riser {
      background: $color-gradient-violeta;
    }

    &.ultra {
      background-color: $color-variante-nueve;
    }

    &[data-impressions='true'] {
      color: $color-oscuro !important;
      background: $color-variante-cinco;
    }

    h1 {
      font-weight: $normal;
      @include font-size(16px);
      line-height: 16px;
    }
  }

  &__header {
    padding: $space_x5 $space_x9 $space_x3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: $color-claro;
    background-color: get-opacity($color-oscuro, 0.8);

    h2,
    h3 {
      font-weight: $light;
      @include font-size(24px);
      line-height: 24px;
    }

    h3 {
      padding: $space 0 0;
      color: $color-claro;
      @include font-size(18px);
      line-height: 18px;
    }

    p {
      display: flex;
      align-items: center;
      font-weight: $normal;
      color: $color-claro;
      @include font-size(14px);
    }

    &__filters {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__chart {
    padding: $space $space_x8;
    width: 100%;
    height: 100%;
    max-height: 462px;
    color: $color-claro;
    background-color: get-opacity($color-oscuro, 0.8);
    font-size: 12px;

    text {
      text-transform: uppercase;
    }
  }

  &__indicators {
    padding: 0 $space_x9 $space_x2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    color: $color-claro;
    background-color: get-opacity($color-oscuro, 0.8);

    p {
      display: flex;
      align-items: center;
      font-weight: $light;
      margin-left: $space_x3;
      color: $color-claro;
      @include font-size(11px);
    }
  }

  &__footer {
    padding: $space_x4 0;
    width: 100%;

    .calltoaction {
      padding: $space $space;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      width: 100%;
      max-width: 250px;
      text-align: left;
      font-weight: $normal;
      background-color: $color-primario;
      @include font-size(16px);
      line-height: 16px;

      &[data-impressions='false'] {
        border: 2px solid $color-variante-cinco !important;
        color: $color-oscuro;
        background: $color-variante-cinco;

        img {
          filter: invert(100%);
        }
      }

      img {
        margin-left: $space;
        width: 12px;
        height: 12px;
        object-fit: cover;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  @include media(desktop-ml) {
    &__container {
      width: 100%;
    }
  }

  @include media(desktop-l) {
    &__container {
      padding: 0;
    }
  }
}
