// Colores

//Brand colors
$color-primario: #0068b5;
$color-secundario: #00c7fd;

//Action colors
$color-variante: #ec6267;
$color-variante-dos: #0040ff;
$color-variante-tres: #cc94da;
$color-variante-cuatro: #3d3d3d;
$color-variante-cinco: #fec91b;
$color-variante-seis: #2d414c;
$color-variante-siete: #072031;
$color-variante-ocho: #253c48;
$color-variante-nueve: #ff8f51;
$color-variante-diez: #fec91b;
$color-variante-once: #525252;
$color-variante-doce: #1f2d36;
$color-variante-trece: #ff5662;
$color-gradient-violeta: linear-gradient(to bottom, #5b69ff, #000f89);
$color-gradient-azul: linear-gradient(to bottom, #0040ff, #0a001f);
$color-unison: #5b69ff;
$color-ultra: #0040ff;

$color-battlestation: #000f8a;
$color-table: #525252;
$color-endcap: #0040ff;

//Boolean colors
$color-negative: #ff0000;
$color-positive: #0cbb06;
$color-warning: #fec91b;

//Background colors
$color-bg-claro: #f5f5f5;
$color-bg-oscuro: #000000;

//Basic colors
$color-claro: #ffffff;
$color-oscuro: #262626;

// ----------------------------------------------------------------------------------------------------------

// Espaciado

//Unidades
$space: 10px;
$space_half: 5px;
$space_x2: 20px;
$space_x3: 30px;
$space_x4: 40px;
$space_x5: 50px;
$space_x6: 60px;
$space_x7: 70px;
$space_x8: 80px;
$space_x9: 90px;
$space_x10: 100px;
$space_x11: 110px;
$space_x12: 120px;
$space_x13: 130px;
$space_x14: 140px;
$space_x15: 150px;

// ----------------------------------------------------------------------------------------------------------

// Fuentes y tamaños de fuentes

// Fuentes
$Fuente1: 'IntelOne Display', sans-serif;

//typos
$h1: 48px;
$h2: 43px;
$h3: 22px;
$p: 18px;
$small: 10px;

//Typo bolds
$black: 900;
$bolder: 700;
$medium: 600;
$normal: 500;
$light: 400;
