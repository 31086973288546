::selection {
  background-color: $color-secundario;
  color: $color-claro;
}

::selection {
  background-color: get-opacity($color-oscuro, 0.8);
  color: $color-claro;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: $color-secundario;
}

::-webkit-scrollbar-thumb {
  background: get-opacity($color-primario, 0.8);
}

::-webkit-scrollbar-thumb:hover {
  background: get-opacity($color-primario, 1);
}

video::-internal-media-controls-download-button {
  display:none !important;
}

video::-webkit-media-controls-enclosure {
  overflow:hidden !important;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px) !important; /* Adjust as needed */
}

// variables css
:root {
  --main-font-size: 15px;
}

@include media(desktop-s) {
  ::-webkit-scrollbar {
    width: 10px;
  }

  :root {
    --main-font-size: 13px;
  }
}

@include media(desktop-m) {
  :root {
    --main-font-size: 14px;
  }
}

@include media(desktop-ml) {
  :root {
    --main-font-size: 15px;
  }
}

@include media(desktop-l) {
  :root {
    --main-font-size: 15px;
  }
}

@include media(desktop-xl) {
  :root {
    --main-font-size: 16px;
  }
}

html,
body {
  // margin: 0 auto;
  width: 100%;
  height: 100vh;
  min-height: 630px;
  font-size: var(--main-font-size);
  font-family: $Fuente1 !important;
  user-select: none;
  scroll-behavior: smooth;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  cursor: auto !important;
  color: $color-oscuro;
  background-color: $color-bg-oscuro;
}
