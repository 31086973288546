.modalaction {
  padding: $space $space_x2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 230px;
  border: none;
  outline: none;
  font-family: $Fuente1;
  font-weight: $normal;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  color: $color-claro;
  background-color: transparent;
  @include font-size(16px);

  &:hover {
    opacity: 0.8;
  }

  &.all {
    background-color: $color-primario;
  }

  &.evo {
    background-color: $color-secundario;
  }

  &.gaming {
    background-color: $color-variante;
  }

  &.riser {
    background-image: $color-gradient-violeta;
  }

  &.ultra {
    background-color: $color-variante-nueve;
  }

  img {
    margin-left: $space;
    width: 16px;
    height: 16px;
    object-fit: cover;
  }
}
