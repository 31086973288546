.main {
  position: relative;
  width: 100%;
  height: 100vh;
  transition: all 0.3s ease-in-out;

  h2 {
    position: fixed;
    top: 45px;
    left: 115px;
    z-index: 2;
    color: $color-claro;
    animation: fadeIn 0.5s ease-in-out 0.2s both;
    @include font-size(40px);
  }

}
