.checkbox {
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;

  &::before {
    content: '';
    font-size: 8px;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: $color-variante-cuatro;
    border: 1px solid $color-claro;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:checked::before {
    background-color: $color-primario;
  }
}