.calltoaction {
  padding: $space_half $space_x4;
  width: 100%;
  max-width: 230px;
  font-family: $Fuente1;
  font-weight: $normal;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: $color-claro;
  background: get-opacity($color-secundario, 0.9);
  @include font-size(16px);

  &.all {
    border: 2px solid $color-primario !important;
    background-color: $color-primario;
  }

  &.evo {
    border: 2px solid $color-secundario !important;
    background-color: $color-secundario;
  }

  &.gaming {
    border: 2px solid $color-variante !important;
    background-color: $color-variante;
  }

  &.riser {
    border: 2px solid $color-gradient-violeta !important;
    background: $color-gradient-violeta;
  }

  &.ultra {
    border: 1px solid $color-variante-nueve !important;
    background: $color-variante-nueve;
  }

  &:hover {
    opacity: 0.8;
  }
}
