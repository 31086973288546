.footer {
  padding: 0 100px 30px 0;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  text-align: right;
  width: 100vw;
  color: $color-claro;
  user-select: none;
  transition: all 0.3s ease-in-out;



  small {
    @include font-size(10px);
  }
}
