.helpscreen {
  position: relative;
  position: fixed;
  z-index: 5;
  top: 50%;
  left: 50%;
  user-select: none;
  width: 100%;
  height: 100vh;
  transition: all 1s ease-in-out;
  transform: translate(-50%, -50%);
  animation: transfromToModal 1s ease-in-out 0.2s both;
  background: get-opacity($color-bg-oscuro, 0.8);
  background: linear-gradient(
    270deg,
    rgba(0, 0, 5, 0.4) 0%,
    rgba(0, 0, 5, 0.8) 53%,
    rgba(0, 0, 5, 0.8) 54%,
    rgba(0, 0, 5, 0.7) 95%,
    rgba(0, 0, 5, 0.5) 100%
  );

  &.hidden {
    animation: transfromToIcon 2s ease-in-out 1s both;

    .helpscreen__center,
    .helpscreen__panel,
    .helpscreen__search,
    .helpscreen__filters {
      animation-name: fadeOut, dissapear;
      animation-duration: 0.3s, 0.3s;
    }
  }

  &__center {
    position: absolute;
    top: 50%;
    left: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: auto;
    text-align: center;
    transform: translate(-50%, -50%);
    animation: fadeIn 1s ease-in-out 0.8s both;

    img {
      width: 129px;
      height: 129px;
      animation: fadeIn 1s ease-in-out 1.2s both;
    }

    p {
      margin: $space_x3 0 $space_x6;
      color: $color-claro;
      animation: fadeIn 1s ease-in-out 1.5s both;
      @include font-size(18px);
    }

    .calltoaction {
      padding: $space_half $space;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 24px;
        height: 24px;
        object-fit: cover;
      }
    }
  }

  &__panel {
    position: relative;
    width: auto;
    height: 100vh;
    animation: fadeIn 1s ease-in-out 0.9s both;

    &__text {
      position: absolute;
      top: 65px;
      left: 500px;
      width: 297px;
      animation: fadeIn 1s ease-in-out 1.5s both;

      p {
        color: $color-claro;
        @include font-size(12px);
      }
    }

    &__border {
      position: absolute;
      top: 0;
      left: 50px;
      width: 400px;
      height: 100vh;
      border: 1px solid $color-secundario;
      animation: fadeIn 1s ease-in-out 2s both;
    }

    .guide {
      margin: 70px auto 0 399px;
      width: 23px;
      height: 0px;
      border-bottom: 1px solid $color-secundario;
    }
  }

  &__search {
    position: absolute;
    top: 0;
    z-index: 1;
    width: auto;
    animation: fadeIn 1s ease-in-out 0.9s both;

    &__text {
      position: absolute;
      top: 125px;
      left: 100px;
      width: 300px;
      animation: fadeIn 1s ease-in-out 1.5s both;

      p {
        color: $color-claro;
        @include font-size(12px);
      }
    }

    &__border {
      position: absolute;
      top: 105px;
      left: 0px;
      width: 50px;
      height: 50px;
      border: 1px solid $color-secundario;
      border-right: none;
      animation: fadeIn 1s ease-in-out 2s both;
    }

    .guide {
      position: absolute;
      top: 25px;
      left: 50px;
      margin: 0;
      width: 23px;
      height: 0px;
      border-bottom: 1px solid $color-secundario;
    }
  }

  &__filters {
    position: absolute;
    top: 0;
    width: auto;
    height: 100vh;
    animation: fadeIn 1s ease-in-out 0.9s both;

    &__text {
      position: absolute;
      top: 340px;
      left: 100px;
      width: 197px;
      animation: fadeIn 1s ease-in-out 1.5s both;

      p {
        color: $color-claro;
        @include font-size(12px);
      }
    }

    &__border {
      position: absolute;
      top: 260px;
      left: 0px;
      width: 50px;
      height: 314px;
      border: 1px solid $color-secundario;
      border-right: none;
      animation: fadeIn 1s ease-in-out 2s both;
    }

    .guide {
      position: absolute;
      top: 95px;
      left: 50px;
      margin: 0;
      width: 23px;
      height: 0px;
      border-bottom: 1px solid $color-secundario;
    }
  }
}

// Ajustar cta en country modal, los valores pasan a K,MT etc
