.header {
  padding: 59px 0 0 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999991;
  user-select: none;
  color: $color-claro;

  h1 {
    display: flex;
    align-items: center;
    margin: 0;
    font-weight: $light;
    transition: all 0.2s ease-in-out;
    @include font-size(48px);
    line-height: 48px;

    &.gaming {
      font-weight: $black;
    }
  }

  button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 $space_x2;
    width: 16px;
    height: 16px;
    outline: none;
    border-radius: 100%;
    cursor: pointer;
    color: $color-claro;
    background: transparent;
    @include font-size(20px);

    &.pulse {
      animation: pulse 4s ease-in-out 0s alternate infinite;
    }
  }

  span {
    margin: 0 0 0 $space_x2;
    cursor: pointer;
    color: $color-claro;
    @include font-size(20px);
    line-height: 20px;
  }
}
