.searchresults {
  padding: $space_x3 $space_x4;
  position: absolute;
  top: 0;
  left: -400px;
  z-index: 1 !important;
  width: 0px;
  height: 100vh;
  opacity: 0;
  border-radius: 0px;
  transition: all 0.3s ease-in-out;
  animation: hide-panel-results 0.2s ease-in-out 0s both;
  color: $color-claro;
  background-color: get-opacity($color-oscuro, 0);

  &.reveal {
    left: 354px;
    width: 360px;
    opacity: 1;
    border-left: 1px solid get-opacity($color-claro, 0.2);
    animation: reveal-panel-results 0.2s ease-in-out 0s both;
    background-color: get-opacity($color-oscuro, 1);

    h3 {
      opacity: 1;
    }
  }

  .error {
    color: $color-negative;
    grid-column: 1 / -1;
  }

  .no-results {
    padding: $space 0;
    grid-column: 1 / -1;
    font-weight: $normal;
    color: $color-warning;
    @include font-size(14px);
  }

  h3 {
    padding: 0 $space_half;
    margin-bottom: $space;
    font-weight: $normal;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    @include font-size(14px);

    span {
      color: get-opacity($color-claro, 0.5);
    }
  }

  &__results {
    padding: 0 0;
    grid-auto-rows: max-content;
    justify-items: start;
    width: 100%;
    height: auto;
    max-height: 85vh;
    overflow-y: auto;
    overflow-x: hidden;
    @include display-grid;
    @include grid-template-columns(50% 50%);

    button {
      position: relative;
      margin: $space_half $space_half;
      width: 124px;
      height: 124px;
      font-family: $Fuente1;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      color: $color-secundario;
      animation: fadeIn 0.3s ease-in-out 0.1s both;
      background-color: get-opacity($color-bg-oscuro, 1);

      h3 {
        margin-bottom: $space_x2;
      }

      &:hover {
        background-color: get-opacity($color-bg-oscuro, 0.2);
      }
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 50px;
      cursor: pointer;
      background-color: get-opacity($color-claro, 0.2);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: get-opacity($color-claro, 0.5);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: get-opacity($color-claro, 1);
    }
  }

  &__showmore {
    padding: $space;
    margin: 0;
    width: auto;
    font-family: $Fuente1;
    font-weight: $normal;
    cursor: pointer;
    color: $color-secundario;
    background-color: transparent;
    @include font-size(14px);
  }

  &__country {
    padding: $space;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background: $color-bg-oscuro;

    img {
      width: 15px;
      object-fit: cover;
    }

    p {
      margin-left: $space;
      white-space: wrap;
      font-weight: $normal;
      text-align: left;
      color: $color-secundario;
      @include font-size(12px);
      line-height: 12px;
    }
  }

  &__retailer {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    margin: $space auto 0;
    font-family: $Fuente1;
    color: $color-claro;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 1;
      background: $color-oscuro;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
    }

    &:hover {
      box-shadow: 0px 0px 5px $color-secundario;
    }

    h3 {
      margin-bottom: $space_x2;
    }

    p {
      font-weight: $normal;
      color: $color-secundario;
    }

    h3 {
      position: absolute;
      left: 5px;
      bottom: 8px;
      z-index: 1;
      text-align: left;
      @include font-size(13px);
      line-height: 14px;
    }
  }

  &__message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    & > div {
      width: 100px;
    }

    img {
      width: 100px;
      aspect-ratio: 1/1;
    }

    p {
      width: 200px;
      text-align: center;
      @include font-size(12px);
    }
  }
}
