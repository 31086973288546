.modalcountry {
  padding: $space_x4 0 0;
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  animation: fadeIn 0.3s ease-in-out 0s both;

  &__spark {
    position: absolute;
    top: -0px;
    right: -0px;
    width: 12px;
    height: 12px;
    transition: all 0.5s ease-in-out;
    background-color: $color-primario;

    &:hover {
      opacity: 0.8;
    }
  }

  &__container {
    padding: $space_x3 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: $space_x3;
    width: 100%;
    height: 100%;
    max-width: 306px;
    transition: all 0.5s ease-in-out;

    &.all {
      color: $color-primario;
      .modalcountry__container__button,
      .modalcountry__spark {
        background-color: $color-primario;
      }

      span {
        color: $color-claro;
      }
    }

    &.evo {
      color: $color-secundario;
      .modalcountry__container__button,
      .modalcountry__spark {
        background-color: $color-secundario;
      }
    }

    &.gaming {
      color: $color-variante;
      .modalcountry__container__button,
      .modalcountry__spark {
        background-color: $color-variante;
      }
    }

    &.riser {
      color: $color-unison;
      .modalcountry__container__button,
      .modalcountry__spark {
        background: $color-unison;
      }
    }

    &.ultra {
      color: $color-variante-nueve;
      .modalcountry__container__button,
      .modalcountry__spark {
        background: $color-variante-nueve;
      }
    }

    h1 {
      margin-bottom: $space_x3;
      font-weight: $light;
      text-transform: capitalize;
      color: $color-claro;
      @include font-size(20px);

      &::first-letter {
        text-transform: uppercase;
      }
    }

    span {
      color: $color-claro;
      @include font-size(18px);
      line-height: 18px;
    }

    p {
      color: $color-claro;
      @include font-size(18px);
      line-height: 18px;
    }
  }

  &__grid {
    padding: $space 0 0;
    align-items: flex-start;
    grid-gap: 8px;
    width: 100%;
    max-width: 260px;
    height: 100%;
    text-align: left;
    @include display-grid;
    @include grid-template-columns(50% 50%);
    align-content: baseline;

    h2 {
      width: 100%;
      font-weight: $medium;
      animation: fadeIn 0.5s ease-in-out 0s both;
      @include font-size(30px);
      line-height: 30px;

      & > section {
        justify-content: flex-start !important;
      }
    }

    span {
      width: 100%;
      @include font-size(11px);
    }

    & > div {
      padding: $space $space;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      position: relative;
      width: 100%;
      height: 70px;
      background-color: $color-bg-oscuro;
    }
  }

  &__daily {
    cursor: pointer;

    &:hover {
      .modalcountry__tooltip {
        display: flex;
      }
    }
  }

  &__tooltip {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    top: -25px;
    left: 5px;
    z-index: 1;
    width: auto;
    min-width: 80px;
    min-height: 30px;
    border-radius: 5px;
    background-color: $color-claro;
    transition: all 0.3s ease-in-out;

    p {
      padding: 0;
      color: $color-bg-oscuro;
      @include font-size(12px);
      line-height: 12px;
    }

    &__icon {
      position: absolute;
      bottom: 0;
      margin: -5px auto;
      width: 20px;
      height: 10px;
      background-color: $color-claro;
      clip-path: polygon(0 0, 50% 100%, 100% 0);
    }
  }

  &__image {
    width: 120px;
    text-align: left;
    animation: fadeIn 0.5s ease-in-out 0s both;

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
  }

  &__button {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 300px;
  }
}
