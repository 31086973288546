.filters {
  padding: $space_x4 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-claro;
  }

  &::-webkit-scrollbar-track {
    background: get-opacity($color-claro, 0.1);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 $space_x2;

    h3 {
      margin: 0;
      color: $color-claro;
      background-color: transparent;
      @include font-size(14px);
    }

    button {
      padding: 0;
      margin: 0;
      font-family: $Fuente1;
      cursor: pointer;
      color: $color-secundario;
      background-color: transparent;
      @include font-size(14px);
    }
  }

  @include media(desktop-xl) {
    &__header {
      h3 {
        @include font-size(12px);
      }

      button {
        @include font-size(12px);
      }
    }
  }
}
