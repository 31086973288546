.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  user-select: none;
  width: 100%;
  height: 100vh;
  transition: all 1s ease-in-out;
  background-image: url('../../images/static/loader__background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: get-opacity($color-bg-oscuro, 1);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeIn 1s ease both 0.5s;
    background: get-opacity($color-secundario, 1);
    background: linear-gradient(
      310deg,
      get-opacity($color-secundario, 0.3) 0%,
      get-opacity($color-bg-oscuro, 0) 30%,
      get-opacity($color-bg-oscuro, 1) 50%,
      get-opacity($color-bg-oscuro, 1) 66%,
      get-opacity($color-variante, 0.3) 100%
    );
    opacity: 0.5;
  }

  &.hidden {
    animation-name: fadeOut, dissapear;
    animation-duration: 1s, 0.4s;
    animation-timing-function: ease-in-out, ease-out;
    animation-delay: 2s, 2.8s;
    animation-fill-mode: both;

    .loader__container {
      animation-name: fadeOut, dissapear;
      animation-duration: 0s, 0s;
      animation-timing-function: ease-in-out, ease-out;
      animation-delay: 2s, 2.8s;
      animation-fill-mode: both;
    }
  }

  &__container {
    position: absolute;
    top: 50%;
    left: 0%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    transform: translateY(-50%);
    transition: all 1s ease-in-out;
    background-color: get-opacity($color-oscuro, 0.4);
  }

  &__text {
    display: flex;
    justify-content: flex-start;
    width: 400px;
    overflow: hidden;
    height: auto;

    h1 {
      margin-bottom: $space_x2;
      font-weight: $normal;
      color: $color-claro;
      opacity: 0;
      animation: slideUp 0.8s ease forwards 1.5s;
      transition: all 1s ease-in-out;
      @include font-size(80px);
      line-height: 70px;
    }
  }

  &__helpers {
    display: flex;
    justify-content: flex-end;
    width: 400px;
    overflow: hidden;
    height: auto;

    p {
      margin-top: $space_x2;
      font-weight: $light;
      color: $color-claro;
      opacity: 0;
      animation: slideUp 0.8s ease forwards 1.5s;
      transition: all 1s ease-in-out;
      @include font-size(20px);
    }
  }

  &__separator {
    margin: 0 $space;
    width: 1px;
    min-height: 0px;
    animation: fadeIn 1s ease both 0.5s;
    transition: all 2s ease-in-out;
    background: $color-claro;
  }
}
