.maps {
  width: 100%;
  height: 100%;

  &__container {
    width: 100%;
    height: 100%;

    & > div {
      background-color: black !important;
    }
  }
}
