.accordion {
  width: 100%;
  height: auto;
  transition: all 0.5s ease-in-out;

  &.active {
    .accordion__header {
      svg {
        transform: rotate(180deg);
      }
    }

    .accordion__panel {
      height: 100%;
      @include display-grid;
    }
  }

  &__header {
    padding: $space 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-family: $Fuente1;
    border: 1px solid get-opacity($color-claro, 0.3) !important;
    border-left: none !important;
    border-right: none !important;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    color: $color-claro;
    background-color: transparent;
    @include font-size(12px);

    &:hover {
      background-color: get-opacity($color-claro, 0.01);
    }

    svg {
      transition: all 0.5s ease-in-out;
      color: get-opacity($color-claro, 0.3);
      @include font-size(18px);
    }
  }

  &__panel {
    padding: $space_x2 0;
    display: none;
    grid-gap: $space;
    width: 100%;
    height: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    text-transform: capitalize;
    transition: all 1s ease-in-out;
    animation: fadeIn 0.5s ease-in-out 0.2s both;
    @include font-size(12px);

    &[data-columns='1'] {
      @include grid-template-columns(auto);
    }

    &[data-columns='2'] {
      @include grid-template-columns(50% 50%);
    }

    &[data-columns='3'] {
      @include grid-template-columns(33% 33% 33%);
    }

    &[data-columns='4'] {
      @include grid-template-columns(25% 25% 25% 25%);
    }

    & > div {
      animation: fadeIn 0.5s ease-in-out 0.2s both;
    }

    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-claro;
    }

    &::-webkit-scrollbar-track {
      background: get-opacity($color-claro, 0.1);
    }
  }
}
