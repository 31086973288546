.errormsg {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 100;

  font-family: $Fuente1;

  &__box {
    width: 390px;
    height: 188px;
    padding: 30px;
    background-color: $color-oscuro;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 16px;

    &__text {
      color: white;
      display: flex;
      justify-content: left;
      align-items: center;

      p {
        margin-left: 13px;
      }
    }

    &__button {
      width: 122px;
      height: 40px;
      padding: 10px 50px 10px 50px;
      background-color: $color-secundario;

      font-family: $Fuente1;
      font-weight: 500;
    }
  }
}
