@keyframes curtain-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes curtain-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes reveal-to-top {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes hide-panel {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-500px);
  }
}

@keyframes reveal-panel {
  0% {
    transform: translateX(-500px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes hide-panel-results {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-0px);
  }
}

@keyframes reveal-panel-results {
  0% {
    transform: translateX(-0px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none !important;
  }
}

@keyframes dissapear {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
    height: 0;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 $color-secundario;
  }
  50% {
    box-shadow: 0px 0px 10px 5px $color-primario;
    color: $color-claro;
    background-color: $color-secundario;
  }
  100% {
    box-shadow: 0 0 0 0 $color-secundario;
  }
}

@keyframes separator-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(300px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes transfromToIcon {
  0% {
    width: 100%;
    height: 100%;
  }
  25% {
    border-radius: 100%;
  }
  30% {
    width: 32px;
    height: 32px;
    transform: translateX(-50%) translateY(100vh);
  }
  100% {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    transform: translate(-50%) translateY(100vh);
  }
}

@keyframes transfromToModal {
  0% {
    width: 32px;
    height: 32px;
    transform: translate(-50%) translateY(100vh);
    border-radius: 100%;
  }
  50% {
    width: 32px;
    height: 32px;
    transform: translate(-50%) translateY(-50%);
  }
  100% {
    width: 100%;
    height: 100%;
    border-radius: 0%;
  }
}

@keyframes rotateIcon180 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
