.analytics {
  padding: $space_x3 50px $space_x3 100px;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99;
  overflow-x: hidden;
  overflow-y: auto !important;
  animation: fadeIn 0.5s ease-in-out;
  transition: all 1s ease-in-out;
  background-image: url('../../images/static/loader__background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: get-opacity($color-bg-oscuro, 1);

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $color-variante-seis;
  }

  h1 {
    margin-bottom: $space_x2 + $space_half;
    animation: fadeIn 0.5s ease-in-out 0.2s both;
    color: $color-claro;
    @include font-size(40px);
    line-height: normal;
  }

  &__filters {
    padding: $space_x2 10px;
    display: grid;
    gap: $space_x2;
    grid-template-columns: repeat(6, minmax(auto, 1fr));
    align-items: center;
  }

  &__paginate {
    padding: ($space + $space_half) $space_half;

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0 0 0 auto;
      width: 100%;
      min-width: 250px;
      max-width: 300px;

      li {
        padding: 7px 12px;
        width: 100%;
        height: 100%;
        max-width: 40px;
        font-family: $Fuente1;
        font-weight: $medium;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        color: $color-claro;

        a {
          padding: 10px 0px;
          text-decoration: none;
          @include font-size(14px);
        }

        &.selected {
          color: $color-oscuro;
          background-color: $color-secundario;
        }
        &.selected:hover {
          color: get-opacity($color-oscuro, 0.7);
        }

        &.disabled {
          visibility: hidden;
          cursor: not-allowed;
          pointer-events: none;
          color: get-opacity($color-claro, 0.5);
          background-color: get-opacity($color-secundario, 0);

          a {
            cursor: not-allowed;
          }

          &:hover {
            color: get-opacity($color-secundario, 0.5);
          }
        }

        &:hover {
          color: get-opacity($color-secundario, 1);
        }
      }
    }
  }

  &__checkboxes {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
  }

  &__selects {
    width: 100%;
    max-width: 250px;
    cursor: pointer !important;
    @include font-size(12px);
    text-transform: capitalize;
    z-index: 10;

    &__placeholder {
      color: $color-claro !important;
      padding-top: 4px;
    }
    &--is-disabled {
      cursor: not-allowed !important;
    }

    &__input-container {
      color: $color-claro !important;

      input {
        text-align: left !important;
        width: 100%;
        text-transform: capitalize !important;
      }
    }

    &__control {
      border-radius: 0 !important;
      border: none !important;
      border: 1px solid $color-claro !important;
      box-shadow: none !important;
      cursor: pointer;
      background-color: get-opacity($color-bg-oscuro, 1) !important;

      &--is--focused {
        background-color: get-opacity($color-claro, 0.2) !important;
      }

      &--is-disabled {
        cursor: not-allowed !important;
        border-bottom: 1px solid get-opacity($color-claro, 0.2) !important;
        background-color: get-opacity($color-claro, 0.05) !important;

        .filter__select__dropdown-indicator {
          color: get-opacity($color-claro, 0.2) !important;
        }
      }
    }

    &__dropdown-indicator {
      background-color: get-opacity($color-oscuro, 1);
    }

    &__loading-indicator {
      color: $color-primario !important;
    }

    &__single-value {
      color: $color-claro !important;

      &--is-disabled {
        cursor: not-allowed !important;
        color: get-opacity($color-claro, 0.2) !important;
      }
    }

    &__value-container {
      color: $color-claro !important;
    }

    &__indicators {
      cursor: pointer !important;

      span {
        display: none !important;
      }
    }

    &__menu {
      margin-top: 0 !important;
      border-radius: 0 !important;
      background-color: get-opacity($color-oscuro, 1) !important;
      z-index: 10;

      &-list {
        max-height: 200px !important;
        border-bottom: 0.5px solid get-opacity($color-claro, 0.2);
        color: $color-claro;
        background-color: get-opacity($color-oscuro, 0) !important;
        z-index: 10;

        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }

        &::-webkit-scrollbar-track {
          background: get-opacity($color-claro, 0.1);
        }

        &::-webkit-scrollbar-thumb {
          background: get-opacity($color-primario, 0.8);
        }

        &::-webkit-scrollbar-thumb:hover {
          background: get-opacity($color-primario, 1);
        }
      }
    }

    &__option {
      padding: $space_half !important;
      cursor: pointer !important;
      border-bottom: 0.5px solid get-opacity($color-claro, 0.2);
      color: $color-claro;
      background-color: get-opacity($color-oscuro, 0.9) !important;
      z-index: 1;

      &:last-child {
        border-bottom: 0.5px solid get-opacity($color-claro, 0);
      }
    }

    &__multi-value {
      max-width: fit-content;
      background-color: $color-primario !important;

      &__label {
        color: $color-claro !important;
      }

      &__remove {
        &:hover {
          color: $color-claro !important;
          background-color: $color-primario !important;
        }
      }
    }

    &__option--is-focused {
      color: $color-claro;
      background-color: get-opacity($color-claro, 0.1) !important;
    }

    &__value-container {
      &::-webkit-scrollbar {
        width: 5px !important;
        height: 4px !important;
      }

      &::-webkit-scrollbar-track {
        background: get-opacity($color-claro, 0.1);
      }

      &::-webkit-scrollbar-thumb {
        background: get-opacity($color-primario, 0.8);
      }

      &::-webkit-scrollbar-thumb:hover {
        background: get-opacity($color-primario, 1);
      }
    }
  }

  &__short-filter {
    max-width: $space_x10;

    .analytics__selects__control {
      border: none !important;
      border-bottom: 1px solid $color-claro !important;
      @include font-size(12px);
    }
    .analytics__selects__dropdown-indicator {
      background-color: transparent;
      svg {
        height: 16px;
        width: 16px;
      }
    }
  }

  &__table {
    border: 1px solid $color-claro;
    animation: fadeIn 0.5s ease-in-out 0.5s both;
    transition: all 1s ease-in-out;
    margin-bottom: $space_x2;
    margin-top: $space_x2 + $space_half;
    z-index: 2;
    position: relative;

    &__geo-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: flex-start;
      padding: 0px 10px 5px 0;
    }

    h2 {
      background-color: $color-variante-doce;
      padding: $space ($space_half + $space);
      color: $color-claro;
      @include font-size(16px);
    }

    table {
      width: 100%;
      box-sizing: content-box;
      transition: all 1s ease-in-out;
      color: $color-claro;
    }

    tr {
      border-top: 1px solid get-opacity($color-secundario, 0.2);
      @include font-size(13px);
      line-height: 13px;

      &:nth-child(1) {
        border-top: none;
      }
    }

    th {
      font-weight: $normal;
      width: 15%;
      color: $color-claro;
      @include font-size(14px);
      border-bottom: 1px solid get-opacity($color-secundario, 0.2);

      &:nth-child(1) {
        padding: 0 $space;
        width: 15%;
      }
    }

    tfoot {
      font-weight: $normal;
      width: 15%;
      color: $color-claro;
      background-color: get-opacity($color-variante-siete, 1);
      @include font-size(14px);

      &:nth-child(1) {
        width: 20%;
      }

      tr {
        border-top: 2px solid get-opacity($color-primario, 0.5);
      }
    }

    span {
      display: inline-block;
      min-width: 20px !important;
      font-variant-numeric: tabular-nums;
    }

    td {
      padding: $space_half 0;
      vertical-align: middle;
      font-variant-numeric: tabular-nums;

      &:nth-child(1) {
        padding: 0 $space;
      }
    }

    // svg {
    //   margin-left: 10px !important;
    //   text-align: right !important;
    //   width: 14px;
    //   scale: 1;

    //   path {
    //     fill: $color-secundario;
    //   }
    // }
  }

  &__grid {
    column-gap: $space_x2;
    row-gap: $space_x2;
    z-index: 0;
    @include display-grid;
    @include grid-template-columns(1fr 400px);
  }

  &__graphics {
    padding: 0 0 $space;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    background-color: get-opacity($color-bg-oscuro, 0.5);
    border: 1px solid $color-claro;
    position: relative;
    z-index: 1;

    &__header {
      padding: $space ($space_half + $space);
      background-color: $color-variante-doce;

      p {
        color: $color-claro;
        @include font-size(14px);
      }
    }

    &__filters {
      padding: $space_x2 ($space_x4 + $space_half) $space_x3 0;
      display: grid;
      justify-items: end;
      gap: $space + $space_half;
      grid-template-columns: repeat(2, minmax(auto, 100px));
      align-items: center;
      justify-content: flex-end;
      z-index: 1;
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 200px;
      text {
        text-transform: uppercase;
      }
    }

    &__footer {
      padding: 0 ($space_x3 + $space_half) 0 $space;
      display: flex;
      align-items: center;

      p {
        display: flex;
        align-items: center;
        margin-right: $space;
        color: $color-claro;
        @include font-size(11px);
      }

      span {
        padding: 5px 5px;
        margin-right: $space_half;
        width: 10px;
        height: 10px;
        background-color: $color-secundario;
      }
    }
  }
  .recharts-tooltip-wrapper {
    z-index: 3;
  }
}
@media screen and (max-width: 1024px) {
  .analytics {
    padding: $space_x3 20px $space_x3 70px;
  }
}
