.viewer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  user-select: none;
  width: 100%;
  height: 100vh;
  transition: all 1s ease-in-out;
  animation: fadeIn 1s ease-in-out s both;
  background-image: url('../../images/static/loader__background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: get-opacity($color-oscuro, 1);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeIn 1s ease both 0.5s;
    background: rgb(0, 203, 255);
    background: linear-gradient(
      310deg,
      get-opacity($color-secundario, 0.3) 0%,
      rgba(254, 245, 248, 0) 30%,
      rgba(21, 20, 21, 1) 50%,
      rgba(0, 0, 0, 1) 66%,
      get-opacity($color-variante, 0.3) 100%
    );
    opacity: 0.5;
  }

  &__close {
    padding: 0;
    position: absolute;
    top: 30px;
    right: 85px;
    z-index: 1;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    @include font-size(15px);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    transform: translate(-50%, -50%);
    animation: fadeIn 1s ease-in-out 0.2s both;

    img {
      width: auto;
      height: 100%;
    }
  }
}
