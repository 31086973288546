.mretailer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  animation: fadeIn 1s ease-in-out 2.5s both;
  background-color: get-opacity($color-oscuro, 0.7);

  &__container {
    padding: $space_x2;
    width: 100%;
    height: 100%;
    max-width: 70%;
    max-height: 60%;

    h2 {
      color: $color-primario;
      @include font-size(40px);
    }
  }
}
