canvas {
  width: 100vw;
  height: 100vh;
  cursor: grab;
  overflow: hidden;

  &.-dragging {
    cursor: grabbing;
  }
  &.-pointer {
    cursor: pointer;
  }
  &.-moving {
    cursor: move;
  }
  &.-zoomIn {
    cursor: zoom-in;
  }
}
