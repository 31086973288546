.modalimp {
  position: relative;
  z-index: 1;
  margin: $space_x6 0 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  animation: fadeIn 1s ease-in-out 0.5s both;

  &__container {
    padding: $space_x2 $space_x2;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: $space_x2;
    width: min-content;
    height: 100%;
    max-width: none;
    max-height: none;
    transition: all 0.5s ease-in-out;

    &__square {
      position: absolute;
      top: -20px;
      right: -20px;
      width: 20px;
      height: 20px;
      background-color: $color-primario;
      transition: all 0.5s ease-in-out;
    }

    &.all {
      border: 2px solid $color-primario;
      color: $color-primario;
      .modalimp__container__square {
        background-color: $color-primario;
      }
    }

    &.evo {
      border: 2px solid $color-secundario;
      color: $color-secundario;
      .modalimp__container__square {
        background-color: $color-secundario;
      }
    }

    &.gaming {
      border: 2px solid $color-variante-dos;
      color: $color-variante;
      .modalimp__container__square {
        background-color: $color-variante-dos;
      }
    }

    &.riser {
      border: 2px solid $color-unison;
      color: $color-unison;
      .modalimp__container__square {
        background-color: $color-unison;
      }
    }

    &.ultra {
      border: 2px solid $color-ultra;
      color: $color-variante-nueve;
      .modalimp__container__square {
        background-color: $color-ultra;
      }
    }

    &__text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      img {
        margin: 0 $space_half;
        width: 27px;
        transform: rotate(0deg);
        transition: transform 0.5s ease-in-out;

        &.negative {
          transform: rotate(180deg);
        }
      }
    }

    &__text2 {
      width: 100%;
      height: 100%;
      @include display-grid;
      @include grid-template-columns(1fr 1fr);

      img {
        width: 27px;
      }
    }

    h1 {
      margin: 0 0 $space_half;
      font-weight: $normal;
      color: $color-claro;
      @include font-size(40px);
      line-height: 30px;
    }

    h2 {
      font-weight: $normal;
      @include font-size(35px);
      line-height: 30px;
    }

    span {
      @include font-size(18px);
      line-height: 18px;
    }

    p {
      padding: $space 0 0 0;
      margin: 0 0 $space;
      color: $color-claro;
      @include font-size(18px);
      line-height: 18px;
    }
  }

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 230px;
  }
}
