.tooltip {
  padding: $space_half $space;
  width: fit-content;
  text-align: center;
  // animation: fadeIn 1s ease-in-out 0.2s both;
  transition: all 0.2s ease-in-out;

  h3 {
    margin: 0 $space_half 0 0;
    white-space: nowrap !important;
    @include font-size(14px);
    line-height: 14px;
  }
}
