.checkbox-label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  cursor: pointer;
  padding: 0px $space;

  &__container {
    height: 18px;
    width: 18px;
  }

  span {
    @include font-size(14px);
    white-space: nowrap;
    color: $color-claro;
    text-align: center;
    margin-left: $space;
  }
}
.checkbox-label__border {
  border: 1px solid $color-claro;
}

.checkbox-label__export {
  border-bottom: 1px solid $color-variante-once;
  display: flex;
  gap: 9px;
  padding: $space 0;
}
