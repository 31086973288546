.error-page {
  padding: $space_x3 100px;
  color: $color-claro;
  position: relative;
  width: 100%;
  height: 100vh;
  transition: all 0.3s ease-in-out;

  h1 {
    @include font-size(50px);
  }

  h2 {
    @include font-size(22px);
  }
}