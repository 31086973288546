.tooltipchart {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  padding: 0 5px;
  height: 50px;
  text-align: center;
  border-radius: 10px;
  color: $color-oscuro;
  background-color: $color-claro;

  &__arrow {
    position: absolute;
    bottom: -10px;
    width: 20px;
    height: 10px;
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    background-color: $color-claro;
  }

  .spark {
    padding: 4px 4px;

    &.evo,
    .impresions.evo {
      background-color: $color-secundario;
    }

    &.gaming,
    .impresions.gaming {
      background-color: $color-variante;
    }

    &.riser,
    .impresions.riser {
      background-color: $color-unison;
    }
    &.ultra,
    .impresions.ultra {
      background-color: $color-variante-nueve;
    }
  }

  p {
    padding: 0 $space_half;
    display: flex;
    align-items: center;
    @include font-size(12px);
  }
}
