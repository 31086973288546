.export-data__button {
  font-family: $Fuente1;
  background-color: $color-secundario;
  height: 40px;
  cursor: pointer;
  padding: 0 16px;
  color: $color-oscuro;
  font-size: 16px;
  font-weight: 500;
  z-index: 1;
  position: relative;
  &:hover {
    opacity: 0.8;
    transition: all 200ms ease-in-out;
  }
}
