.export-data-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  &__content {
    background-color: $color-oscuro;
    position: relative;
    color: $color-claro;
    width: 389px;
    padding: $space_x2 $space_x3;
  }
  &__text {
    padding-bottom: $space_x2;
    font-family: $Fuente1;
    font-size: 16px;
    font-weight: 500;
  }
  &__footer {
    padding-top: $space_x3;
    display: flex;
    justify-content: end;
    align-items: center;
  }
  &__close-button {
    background: transparent;
    font-family: $Fuente1;
    height: 40px;
    cursor: pointer;
    padding: 0 16px;
    color: $color-claro;
    font-size: 16px;
    font-weight: 500;
    &:hover {
      opacity: 0.8;
      transition: all 200ms ease-in-out;
    }
  }
}
