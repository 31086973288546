// FONT TYPE DEFINITIONS
@font-face {
  font-family: 'IntelOne Display';
  src: url('../../fonts/intelone-display-medium.eot');
  src: url('../../fonts/intelone-display-medium.woff') format('woff'),
    url('../../fonts/intelone-display-medium.otf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IntelOne Display';
  src: url('../../fonts/intelone-display-regular.eot');
  src: url('../../fonts/intelone-display-regular.woff') format('woff'),
    url('../../fonts/intelone-display-regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IntelOne Display';
  src: url('../../fonts/intelone-display-bold.eot');
  src: url('../../fonts/intelone-display-bold.woff') format('woff'),
    url('../../fonts/intelone-display-bold.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IntelOne Display';
  src: url('../../fonts/intelone-display-light.eot');
  src: url('../../fonts/intelone-display-light.woff') format('woff'),
    url('../../fonts/intelone-display-light.otf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

/*@font-face {
    font-family: 'IntelOne Display';
    src: url('../../fonts/intel-clear-italic.eot');
    src: url('../../fonts/intel-clear-italic.woff') format('woff'),
        url('../../fonts/intel-clear-italic.otf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IntelOne Display';
    src: url('../../fonts/intelone-display-bold-italic.eot');
    src: url('../../fonts/intelone-display-bold-italic.woff') format('woff'),
        url('../../fonts/intelone-display-bold-italic.otf') format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}



@font-face {
    font-family: 'IntelOne Display';
    src: url('../../fonts/intelone-display-light-italic.eot');
    src: url('../../fonts/intelone-display-light-italic.woff') format('woff'),
        url('../../fonts/intelone-display-light-italic.otf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}*/
