.cardretailers {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  margin: $space auto 0;
  width: 120px;
  height: 120px;
  font-family: $Fuente1;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: $color-claro;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: get-opacity($color-bg-oscuro, 1);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    z-index: 1;
    background: $color-oscuro;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
  }

  &:hover {
    box-shadow: 0px 0px 5px $color-secundario;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  p {
    padding: 0 $space 0;
    margin: 0 0 $space;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 1;
    width: 100%;
    text-align: left;
    font-weight: $normal;
    color: $color-claro;
    @include font-size(10px);
  }

  span {
    font-weight: $normal;
    color: $color-secundario;
    @include font-size(10px);
  }
}
