.filter__select {
  margin-left: $space_x2;
  width: 120px;
  cursor: pointer !important;
  @include font-size(12px);

  &--is-disabled {
    cursor: not-allowed !important;
  }

  &__input-container {
    color: $color-claro !important;

    input {
      text-align: left !important;
      width: 100%;
      text-transform: uppercase !important;
    }
  }

  &__control {
    border-radius: 0 !important;
    border: none !important;
    border-bottom: 1px solid $color-claro !important;
    box-shadow: none !important;
    cursor: pointer;
    background-color: get-opacity($color-oscuro, 0.5) !important;

    &--is--focused {
      background-color: get-opacity($color-claro, 0.2) !important;
    }

    &--is-disabled {
      cursor: not-allowed !important;
      border-bottom: 1px solid get-opacity($color-claro, 0.2) !important;
      background-color: get-opacity($color-claro, 0.05) !important;

      .filter__select__dropdown-indicator {
        color: get-opacity($color-claro, 0.2) !important;
      }
    }
  }

  &__single-value {
    color: $color-claro !important;

    &--is-disabled {
      cursor: not-allowed !important;
      color: get-opacity($color-claro, 0.2) !important;
    }
  }

  &__value-container {
    color: $color-claro !important;
  }

  &__indicators {
    cursor: pointer !important;
    span {
      display: none !important;
    }
  }

  &__menu {
    margin-top: 0 !important;
    border-radius: 0 !important;
    background-color: get-opacity($color-oscuro, 1) !important;

    &-list {
      max-height: 200px !important;
      border-bottom: 0.5px solid get-opacity($color-claro, 0.2);
      background-color: get-opacity($color-oscuro, 0) !important;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: get-opacity($color-claro, 0.1);
      }

      &::-webkit-scrollbar-thumb {
        background: get-opacity($color-primario, 0.8);
      }

      &::-webkit-scrollbar-thumb:hover {
        background: get-opacity($color-primario, 1);
      }
    }
  }

  &__option {
    padding: $space_half !important;
    cursor: pointer !important;
    text-transform: uppercase !important;
    border-bottom: 0.5px solid get-opacity($color-claro, 0.2);
    background-color: get-opacity($color-oscuro, 0.9) !important;

    &:last-child {
      border-bottom: 0.5px solid get-opacity($color-claro, 0);
    }
  }

  &__option--is-focused {
    background-color: get-opacity($color-claro, 0.1) !important;
  }
}
