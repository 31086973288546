.aside {
  position: fixed;
  z-index: 3;
  display: block;
  align-items: center;
  flex-direction: row;
  width: 48px;
  height: 100vh;
  transition: all 0.3s ease-in-out;

  &.isVisible {
    width: auto !important;
    display: flex;

    .aside__container {
      animation: reveal-panel 0.25s ease-in-out 0s both;
    }

    // .aside__actions__btn {
    //   &:hover {
    //     p {
    //       transform: translateX(-100%);
    //     }
    //   }
    // }
  }

  &.isExpand {
    width: auto;

    .aside__actions {
      width: 230px;

      &__btn {
        &:first-child {
          img {
            transform: rotate(180deg);
          }
        }

        p {
          transition: all 0.3s ease-in-out;
          transform: translateX(-164px);
        }
      }
    }
  }

  &__actions {
    padding: $space_x4 0 0 0;
    position: relative;
    top: 0;
    left: 0;
    z-index: 3;
    width: 48px;
    height: 100vh;
    box-shadow: 2px 0 20px 0 rgba(90, 196, 248, 0.31);
    transition: all 0.3s ease-in-out;
    background-color: $color-oscuro;

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: $space 0 0;
      width: 48px;
      height: 48px;
      font-family: $Fuente1;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      background-color: $color-oscuro;

      img {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s ease-in-out;
        background-color: $color-oscuro;
      }

      p {
        padding: 0 0 0 ($space_x2 + $space_half);
        text-align: left;
        display: flex;
        align-items: center;
        position: absolute;
        left: 90%;
        z-index: 1;
        width: 187px;
        height: 48px;
        transition: all 0.4s ease-in-out;
        color: $color-claro;
        transform: translateX(-100%);
        background-color: $color-oscuro;
        font-size: 14px;
      }

      &[data-tab='true'] {
        background-color: $color-variante-cuatro;

        img,
        p {
          background-color: $color-variante-cuatro;
        }
      }

      &[data-filter='true'] {
        background-color: $color-variante-cuatro;

        img,
        p {
          background-color: $color-variante-cuatro;
        }
      }

      &:first-child {
        margin: $space 0 0 auto;
        justify-content: flex-end;
        background-color: transparent;

        img {
          background-color: transparent !important;
        }
      }

      &:hover {
        background-color: $color-variante-cuatro;

        img,
        p {
          color: $color-claro;
          transform: translateX(-0%);
          background-color: $color-variante-cuatro;
        }
      }
    }

    hr {
      margin: $space_x3 0;
      border: 0.3px solid get-opacity($color-claro, 0.05);
    }

    &__help {
      position: fixed;
      top: 0px;
      right: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // margin: $space 0 0;
      width: 48px;
      height: 48px;
      font-family: $Fuente1;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      // background-color: $color-oscuro;
      background-color: transparent;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  &__container {
    padding: $space_x6 $space_x3 $space_x3 $space_x5;
    position: relative;
    top: 0;
    left: 0px;
    z-index: 2 !important;
    width: 354px;
    height: 100vh;
    transition: all 0.4s ease-in-out;
    animation: hide-panel 0.25s ease-in-out 0s both;
    background-color: get-opacity($color-oscuro, 1);
  }

  &__header {
    h3,
    p {
      color: $color-claro;
      @include font-size(14px);
    }

    h3 {
      font-weight: $normal;
    }

    p {
      padding: $space_x2 0 0 0;
      max-width: 318px;
    }
  }
}
